import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

export default function Pricing() {
  const data = useStaticQuery(graphql`
    query {
      magicpattern: file(
        relativePath: { eq: "featuredphotos/04-CRTVbox-MagicPattern-03.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 95, width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      cutout: file(relativePath: { eq: "products/Cutout.png" }) {
        childImageSharp {
          fixed(quality: 95, width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      raw: file(relativePath: { eq: "products/raw.png" }) {
        childImageSharp {
          fixed(quality: 90, width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      stilllife: file(
        relativePath: { eq: "featuredphotos/02-HoneyAndSaltCakeDesign-208.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 90, width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      creative: file(
        relativePath: { eq: "featuredphotos/00-ThePopCo-Apple1.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 90, width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      onlocation: file(
        relativePath: { eq: "featuredphotos/01-DesertFlower-Feb13-7132.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 90, width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout title="Pricing Info">
      <SEO
        title="Find the Content Creation in Your Budget"
        keyWords={[
          `Social Media Content Creator`,
          `Social Media Agency`,
          `Albuquerque`,
          `Albuquerque New Mexico`,
          `Product Photography`,
          `Rio Rancho, NM`,
          `Food Photography`,
          `Brand Content`,
          `ABQ Local`,
          `Comercial Photography`,
          `Soona Albuquerque`,
        ]}
      />
      <div className="main" style={{ textAlign: "center" }}>
        <h2>Photo Styles</h2>

        <section className="photostyles">
          <div className="photostyle-item">
            <Img fixed={data.stilllife.childImageSharp.fixed} />
            <h3>Still Life</h3>
            <div className="photostyle-captions">
              <p>
                Let's work together to create customized scene setups for your
                products that match your brand and photo style.
              </p>
              <ul className="pricingli">
                <li>Up to 3 Products</li>
                <li>10+ Photos</li>
                <li>
                  <b>$250</b>
                </li>
              </ul>
            </div>
          </div>

          <div className="photostyle-item">
            <Img fixed={data.creative.childImageSharp.fixed} />
            <h3>Creative</h3>
            <div className="photostyle-captions">
              <p>
                Sometimes a still-life photo isn't enough. Together, we can
                design a magical scene that will push your brand beyond the
                traditional.
              </p>
              <ul className="pricingli">
                <li>1 Product</li>
                <li>1 Concept</li>
                <li>1-2 Images</li>
                <li>
                  <b>$50-$200*</b>
                </li>
              </ul>
              <p>
                <i>
                  *Pricing is determined by complexity, concept, and execution.
                </i>
              </p>
            </div>
          </div>

          <div className="photostyle-item">
            <Img fixed={data.onlocation.childImageSharp.fixed} />
            <h3>On-location</h3>
            <div className="photostyle-captions">
              <p>
                Let me take care of capturing your business or pop-up setup
                on-location.
              </p>
              <p style={{ textAlign: "center" }}>
                <i>1 Hour On-Location</i>
              </p>
              <ul className="pricingli">
                <li>3 Products + Environment</li>
                <li>15 Photos</li>
                <li>
                  <b>$250</b>
                </li>
              </ul>
              <p style={{ textAlign: "center" }}>
                <i>2 Hour On-Location</i>
              </p>
              <ul className="pricingli">
                <li>6 Products + Environment</li>
                <li>25 Photos</li>
                <li>
                  <b>$400</b>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <hr style={{ color: "black" }} />
        <h2>Add-Ons</h2>
        <section className="addons">
          <div className="addon-item">
            <Img fixed={data.cutout.childImageSharp.fixed} />

            <p>Remove Background - $5</p>
          </div>
          <div className="addon-item">
            <Img fixed={data.magicpattern.childImageSharp.fixed} />
            <p>Magic Pattern - $5</p>
          </div>
          <div className="addon-item">
            <Img fixed={data.raw.childImageSharp.fixed} />

            <p>Buy the RAW - $5</p>
          </div>
        </section>
      </div>
    </Layout>
  )
}
